import { BaseSyntheticEvent, useContext, useRef, useState } from "react";
import { AccountContext } from "../../controls/nav/AccountProvider";
import { IonInput } from "@ionic/react";
import { ButtonRef, SubmitButton } from "../../controls/shared/Buttons";
import { UpdatePartnerWebhookUrlAsync } from "../../../lib";

export default function PartnerWebhookPage() {
    const accountContext = useContext(AccountContext);
    const submitButtonRef = useRef<ButtonRef>(null);
    const [webhookUrl, setWebHookUrl] = useState<string | undefined>(accountContext?.partner?.webhookUrl);

    async function saveWebhookUrl() {
        try {
            await UpdatePartnerWebhookUrlAsync(webhookUrl!);
            await accountContext?.refresh();
        }
        catch(e){
            alert(e);
        }
        finally {
            submitButtonRef.current && submitButtonRef.current.stopSpinning();
        }
    }

    if (!accountContext?.partner) return <></>;

    return <>
        <div style={{ display: "inline-flex", alignItems: "end", width: "100%", marginBottom: "20px" }}>
            <div style={{ marginRight: "50px", width: "80%" }}>
                <label>Webhook URL</label>
                <IonInput value={webhookUrl} onKeyUp={(e: BaseSyntheticEvent) => setWebHookUrl(e.target.value!)} />
            </div>

            <SubmitButton 
                text="Save" 
                ref={submitButtonRef} 
                onClick={saveWebhookUrl} 
                disabled={!webhookUrl || webhookUrl === accountContext?.partner?.webhookUrl} />
        </div>
    </>;
}