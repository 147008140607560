import { useHistory } from "react-router";
import { SubmitButton, TextButton } from "../controls/shared/Buttons";
import { Routes } from "../../Routes";
import { useEffect, useRef, useState } from "react";
import { GetImports, Import, ImportStatus } from "../../lib/data/Import";
import { format } from "date-fns";

export default function ImportListPage() {
    const history = useHistory();
    const [continuation, setContinuation] = useState<string | undefined>();
    const [imports, setImports] = useState<Import[]>();
    const lockApiCalls = useRef(false);

    useEffect(() => { RetrieveImports(); }, []);

    async function RetrieveImports() {
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        try {
            const result = await GetImports(continuation);

            if (continuation && imports) setImports(prev => [...prev!, ...result.data]);
            else setImports(result.data);

            setContinuation(result.continuation);
        }
        finally {
            lockApiCalls.current = false;
        }
    }

    return <>
        <div style={{ margin: "80px", marginTop: "40px" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between", marginBottom: "20px" }}>
                <h1>Imports</h1>
                <SubmitButton text="Create new import" onClick={() => history.push(Routes.ImportCreate)} />
            </div>
            <table style={{ width: "100%" }}>
                <thead>
                    <tr>
                        <th>File Name</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                    <tr style={{lineHeight: "10px"}}><th>&nbsp;</th></tr>
                </thead>
                <tbody>
                    {imports?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>{format(item.created, "dd/MM/yyyy")}</td>
                                <td style={{ color: item.status === ImportStatus.Complete ? "#17DA36" : "#FFA700" }}>
                                    {ImportStatus[item.status]}
                                </td>
                                <td><TextButton onClick={() => history.push(Routes.Import(item.id))}>View</TextButton></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </>
}