import { ApiGet, ApiPost } from '..';

export type Account = {
    applied: boolean,
};

const LoadAccountFromApi = async () => {
    var apiAccount = await ApiGet("GetAccount");
    return apiAccount?.data;
}

export const LoadAccount = async () => {
    var apiAccount = await LoadAccountFromApi() as Account;

    if (apiAccount) {
        return apiAccount;
    }

    // todo: could not get account details from storage or api, what do?
    return null;
}

export const ResetAccount = async () => {
    await ApiPost("PostAccountReset", {});
}
