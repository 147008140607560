export const Routes = {
    Home: "/",
    Imports: "/imports",
    ImportCreate: "/importcreate",
    Import: (id: string) => `${Routes.Imports}/${id}`,
    Invitation: (id: string) => `/invitations/${id}`,
    InvitationCreate: "/invitationcreate",
    InvitationSearch: "/invitationsearch",
    ApiKeys: "/apikeys",
    Webhook: "/webhook",
}