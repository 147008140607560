import { ApiGet, ApiPost } from "../util/Api";
import { AppendQuerystring } from "../util/Querystring";

export interface Invitation {
    id: string,
    status: InvitationStatus,
    created: Date,
    updated: Date,
    payload: InvitationPayload,
    association: InvitationAssociation
}

export interface InvitationPayload {
    merchantName?: string,
    merchantEmail?: string,
    paymentTypes: string[],
}

export interface InvitationList {
    data: Invitation[],
    continuation: string,
}

export enum InvitationStatus {
    New = 10,
    Sent = 20,
    Opened = 50,
    Completed = 100,
}

export interface InvitationAssociation {
    merchantId: string,
    accountId: string,
}

export type MerchantApiKeys = {
    id: string,
    key: string,
}

export async function ListInvitations(searchText?: string, continuation?: string): Promise<InvitationList> {
    var url = "invitations";
    if (continuation && continuation > "") url = AppendQuerystring(url, "continuation", continuation);
    if (searchText && searchText > "") url = AppendQuerystring(url, "search", searchText);
    var response = await ApiGet(url);
    if (response.status === 200) return response.data;
    throw response.data;
}

export async function GetInvitation(invitationId: string): Promise<Invitation> {
    var response = await ApiGet(`invitations/${invitationId}`);
    if (response.status === 200) return response.data;
    throw response.data;
}

export async function CreateInvitation(payload: InvitationPayload): Promise<Invitation> {
    var response = await ApiPost("invitations", payload);
    if (response.status === 200) return response.data;
    throw response.data;
}

export async function GetMerchantApiKeysAsync(invitationId: string) : Promise<MerchantApiKeys> {
    var response = await ApiGet(`invitations/${invitationId}/apikeys`);
    if (response.status.toString().startsWith('2')) return response.data;
    throw response.data;
}

export async function RecycleMerchantApiKeysAsync(invitationId: string) : Promise<MerchantApiKeys> {
    var response = await ApiPost(`invitations/${invitationId}/apikeys`);
    if (response.status === 200) return response.data;
    throw response.data;
}