import { Import, UploadImportFile } from "../../lib";
import { FileDragDropUpload, FileDragDropUploadRef } from "../controls/shared/FileDragDropUpload";
import React, { useState } from "react";

export default function ImportCreatePage() {
    const uploadRef = React.createRef<FileDragDropUploadRef>();
    const [result, setResult] = useState<Import>();

    async function uploadImportFile(file: File) {
        try {
            const response = await UploadImportFile(file);
            setResult(response);
        }
        finally {
            uploadRef.current?.stopSpinning();
        }
    }

    return <>
        <div style={{ paddingTop: "100px" }}>
            <FileDragDropUpload onChange={f => uploadImportFile(f)} ref={uploadRef} />
            {result && <p className="center">Upload now processing</p>}
        </div>
    </>
}