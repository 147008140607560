import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Invitation, InvitationStatus, ListInvitations } from "../../lib";
import { format } from "date-fns";

interface ImportViewParams {
    id: string,
};

export default function ImportViewPage() {
    const { id } = useParams<ImportViewParams>();
    const [invitations, setInvitations] = useState<Invitation[]>();
    const [continuation, setContinuation] = useState<string | undefined>();

    useEffect(() => {
        if (!id) return;
        ListInvitations(id).then(response => {
            setInvitations(response.data);
            setContinuation(response.continuation);
        });
    }, []);

    return <>
        <div style={{ margin: "80px", marginTop: "40px" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between", marginBottom: "20px" }}>
                <h1>Import Invitations</h1>
            </div>
            <table style={{ width: "100%" }}>
                <thead>
                    <tr>
                        <th>Merchant Name</th>
                        <th>Merchant Email</th>
                        <th>Updated</th>
                        <th>Status</th>
                    </tr>
                    <tr style={{lineHeight: "10px"}}><th>&nbsp;</th></tr>
                </thead>
                <tbody>
                    {invitations?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.payload.merchantName}</td>
                                <td>{item.payload.merchantEmail}</td>
                                <td>{format(item.updated, "dd/MM/yyyy")}</td>
                                <td style={{ color: item.status === InvitationStatus.Completed ? "#17DA36" : "#FFA700" }}>
                                    {InvitationStatus[item.status]}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </>;
}