import { useEffect, useRef, useState } from "react";
import { GetPartnerApiKeysAsync, PartnerApiKeys, RecyclePartnerApiKeysAsync } from "../../lib";
import PageSpinner from "../controls/shared/PageSpinner";
import { IonContent, IonInput, IonPage } from "@ionic/react";
import { SubmitButton } from "../controls/shared/Buttons";

export default function PartnerApiKeysPage() {
    const [apiKeys, setApiKeys] = useState<PartnerApiKeys>();
    const [retrieved, setRetrieved] = useState(false);
    const lockApiCalls = useRef(false);

    useEffect(() => {
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        loadApiKeys();

        async function loadApiKeys() {
            try {
                var response = await GetPartnerApiKeysAsync();
                setRetrieved(true);
                setApiKeys(response);
            } finally {
                lockApiCalls.current = false;
            }
        }
    }, []);

    async function generateApiKeys() {
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        try {
            const result = await RecyclePartnerApiKeysAsync();
            setApiKeys(result);
        } finally {
            lockApiCalls.current = false;
        }
    }

    if (!retrieved) return <PageSpinner />;

    return <>
        <IonPage>
            <IonContent className="ion-padding" fullscreen>
                <div style={{ margin: "80px", marginTop: "40px", width: "80%" }}>
                    <h1>Partner API Keys</h1>
                    {apiKeys ? <>
                        <table>
                            <tbody>
                                <tr>
                                    <th style={{ padding: "5px" }}>Client ID</th>
                                    <td style={{ padding: "5px", width: "500px" }}>
                                        <IonInput disabled value={apiKeys?.clientId} />
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ padding: "5px" }}>Client Secret</th>
                                    <td style={{ padding: "5px", width: "500px" }}>
                                        <IonInput disabled value={apiKeys?.clientSecret} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </> : <>
                        <p>API keys have not been generated for this partner.</p>
                        <SubmitButton text="Generate API Keys" onClick={generateApiKeys} />
                    </>}
                </div>
            </IonContent>
        </IonPage>
    </>;
}