import { IonGrid, IonRow, IonCol, IonImg, IonSpinner } from "@ionic/react";

export default function LoadingControl() {
    return <>
        <IonGrid>
            <IonRow>
                <IonCol></IonCol>
                <IonCol sizeXl='3' sizeLg='4' sizeMd='6' sizeSm='8' sizeXs='10'>
                    <br></br>
                    <IonSpinner className='spin' />
                </IonCol>
                <IonCol></IonCol>
            </IonRow>
        </IonGrid>
    </>;
}