import { useEffect, useRef, useState } from "react";
import { GetMerchantApiKeysAsync, MerchantApiKeys, RecycleMerchantApiKeysAsync } from "../../../lib";
import PageSpinner from "../../controls/shared/PageSpinner";
import { IonInput } from "@ionic/react";
import { SubmitButton } from "../../controls/shared/Buttons";

export interface MerchantApiKeysProps {
    merchantId: string,
}

export default function MerchantApiKeysPanel({ merchantId }: MerchantApiKeysProps) {
    const [apiKeys, setApiKeys] = useState<MerchantApiKeys>();
    const [retrieved, setRetrieved] = useState(false);
    const lockApiCalls = useRef(false);

    useEffect(() => {
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        loadApiKeys();

        async function loadApiKeys() {
            try {
                var response = await GetMerchantApiKeysAsync(merchantId);
                setRetrieved(true);
                setApiKeys(response);
            } finally {
                lockApiCalls.current = false;
            }
        }
    }, []);

    async function generateApiKeys() {
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        try {
            const result = await RecycleMerchantApiKeysAsync(merchantId);
            setApiKeys(result);
        } finally {
            lockApiCalls.current = false;
        }
    }

    if (!retrieved) return <PageSpinner />;

    return <>
        <h2>Merchant API Keys</h2>
        {apiKeys ? <>
            <table>
                <tr>
                    <th style={{ padding: "5px" }}>Public Key</th>
                    <td style={{ padding: "5px", width: "500px" }}>
                        <IonInput disabled value={apiKeys?.id} />
                    </td>
                </tr>
                <tr>
                    <th style={{ padding: "5px" }}>Private Key</th>
                    <td style={{ padding: "5px", width: "500px" }}>
                        <IonInput disabled value={apiKeys?.key} />
                    </td>
                </tr>
            </table>
        </> : <>
            <p>API keys have not been generated for this merchant.</p>
            <SubmitButton text="Generate API Keys" onClick={generateApiKeys} />
        </>}
    </>;
}