import { IonInput } from "@ionic/react";
import { useContext, useState } from "react";
import { SubmitButton } from "../controls/shared/Buttons";
import { RegisterPartnerAsync } from "../../lib";
import { AccountContext } from "../controls/nav/AccountProvider";
import { RowWithDynamicGutter } from "../controls/shared/Grid";

export default function RegisterPage() {
    const [name, setName] = useState<string>();
    const accountContext = useContext(AccountContext);

    async function createPartner() {
        if (!name) return;
        await RegisterPartnerAsync(name);
        accountContext?.refresh();
    }

    return <>
        <RowWithDynamicGutter>
            <h1 className="mainHeader center" style={{ marginTop: "150px" }}>Account Setup</h1>
            <p>Please enter your business name below. When your account is ready you'll receive an email.</p>
            <br />
            <b>Business Name</b>
            <IonInput className="input" onIonChange={val => setName(val.detail.value!)} />
            <br />
            <SubmitButton class="widthFullButton" onClick={createPartner} text="Complete &raquo;" disabled={!name} />
        </RowWithDynamicGutter>
    </>
}