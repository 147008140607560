import { useEffect, useRef, useState } from "react";
import { IonCol, IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonNote, IonPage, IonRow } from "@ionic/react";
import { GetPartnerWebhookEventsAsync, PartnerWebhookEvent } from "../../lib";
import Spinner from "../controls/shared/Spinner";
import WebhookUrlControl from "./controls/WebhookUrlControl";

export default function PartnerWebhookPage() {
    const [webhookEvents, setWebhookEvents] = useState<PartnerWebhookEvent[]>([]);
    const [continuation, setContinuation] = useState<string | null>(null);
    const [webhookEventsLoading, setWebhookEventsLoading] = useState(true);
    const lockApiCalls = useRef(false);

    useEffect(() => {
        RetrieveData();
    }, []);

    async function RetrieveData() {
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        try {
            var response = await GetPartnerWebhookEventsAsync(continuation);
            setWebhookEvents(response.events);
            setContinuation(response.continuation);
        } finally {
            lockApiCalls.current = false;
            setWebhookEventsLoading(false);
        }
    }

    function renderWebhookEvents() {
        if (webhookEventsLoading) return <Spinner />;
        if (webhookEvents.length === 0) return <p>No webhook events found.</p>;

        return <>
            <IonRow style={{ borderBottom: "1px solid #a7a7a7" }}>
                <IonCol sizeXs='2'>
                    <IonNote style={{ color: "#868686", fontSize: "11px", fontWeight: "700" }}>
                        Transaction ID
                    </IonNote>
                </IonCol>
                <IonCol sizeXs='1'>
                    <IonNote style={{ color: "#868686", fontSize: "11px", fontWeight: "700" }}>
                        Status
                    </IonNote>
                </IonCol>
                <IonCol sizeXs='2'>
                    <IonNote style={{ color: "#868686", fontSize: "11px", fontWeight: "700" }}>
                        Event
                    </IonNote>
                </IonCol>
                <IonCol sizeXs='6' >
                    <IonNote style={{ color: "#868686", fontSize: "11px", fontWeight: "700" }}>
                        Payload
                    </IonNote>
                </IonCol>
            </IonRow>

            {webhookEvents.map((e, i) => {
                return (
                    <IonItem lines="none" id={`event_${i}`} key={i} style={{ alignItems: "start" }}>
                        <IonCol sizeXs='2' style={{ color: "#003466", fontSize: "11px" }}>
                            {formatTimestamp(e.timestamp)}
                        </IonCol>
                        <IonCol sizeXs='1' style={{ color: "#003466", fontSize: "11px" }}>
                            {e.statusCode}
                        </IonCol>
                        <IonCol sizeXs='2' style={{ color: "#003466", fontSize: "11px" }}>
                            {e.event}
                        </IonCol>
                        <IonCol sizeXs='6' style={{ color: "#003466", fontSize: "11px" }}>
                            {e.data}
                        </IonCol>
                    </IonItem>
                );
            })}

            {continuation && <IonInfiniteScroll threshold="200px" onIonInfinite={() => RetrieveData()}>
                <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Loading...">
                </IonInfiniteScrollContent>
            </IonInfiniteScroll>}
        </>;

        function formatTimestamp(timestamp: Date): string {
            const year = timestamp.getFullYear();
            const month = String(timestamp.getMonth() + 1).padStart(2, "0");
            const day = String(timestamp.getDate()).padStart(2, "0");
            const hours = String(timestamp.getHours()).padStart(2, "0");
            const minutes = String(timestamp.getMinutes()).padStart(2, "0");
            const seconds = String(timestamp.getSeconds()).padStart(2, "0");

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }
    }

    return <>
        <IonPage>
            <IonContent className="ion-padding" fullscreen>
                <div style={{ margin: "80px", marginTop: "40px", width: "80%" }}>
                    <h1>Partner Webhook</h1>
                    <WebhookUrlControl />

                    <h2>Webhook Events</h2>
                    {renderWebhookEvents()}
                </div>
            </IonContent>
        </IonPage>
    </>;
}