import { useHistory } from "react-router";
import { Routes } from "../../../Routes";
import { IonButton } from "@ionic/react";

export default function ContentHeader() {
    const history = useHistory();

    return <>
        <div style={{ display: "flex", justifyContent: "end", margin: "20px", zIndex: 99, position: "relative", alignItems: "center" }}>
            <a href="https://www.trustisttransfer.com/docs" target='_blank' rel='noreferrer' style={{textDecoration: "none"}}>DOCUMENTATION</a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <IonButton
                color="secondary"
                size="default"
                onClick={() => history.push(Routes.InvitationCreate)}>
                Invite New Client
            </IonButton>
        </div>
    </>;
}