import { ApiGet, ApiPost } from '..';

export type Partner = {
    name: string,
    status: string,
    webhookUrl: string,
};

export type PartnerApiKeys = {
    clientId: string,
    clientSecret: string,
};

export type PartnerWebhookEvent = {
    timestamp: Date,
    event: string,
    data: string,
    statusCode: number,
}

export type PartnerWebhookEventResponse = {
    events: PartnerWebhookEvent[],
    continuation: string | null,
}

export async function GetPartnerAsync() : Promise<Partner | null> {
    var response = await ApiGet("GetPartner");
    if (response.status.toString().startsWith('2')) return response.data;
    return null;
}

export async function RegisterPartnerAsync(name: string) : Promise<boolean> {
    var response = await ApiPost("RegisterPartner", {name});
    return response.status === 200;
}

export async function GetPartnerApiKeysAsync() : Promise<PartnerApiKeys> {
    var response = await ApiGet("PartnerApiKeys");
    if (response.status.toString().startsWith('2')) return response.data;
    throw response.data;
}

export async function RecyclePartnerApiKeysAsync() : Promise<PartnerApiKeys> {
    var response = await ApiPost("RecyclePartnerApiKey");
    if (response.status === 200) return response.data;
    throw response.data;
}

export async function GetPartnerWebhookEventsAsync(continuation: string | null) : Promise<PartnerWebhookEventResponse> {
    var url = "PartnerWebhookEvents";
    if (continuation) url += `?continuation=${continuation}`;
    var response = await ApiGet(url);
    if (response.status === 200) return response.data;
    throw response.data;
}

export async function UpdatePartnerWebhookUrlAsync(url: string) : Promise<boolean> {
    var response = await ApiPost("UpdatePartnerWebhookUrl", {url});
    if (response.status.toString().startsWith('2')) return response.data;
    throw response.data;
}