import { ApiGet, ApiPost } from "../util/Api";
import { InvitationList } from "./Invitation";

export interface Import {
    id: string,
    created: Date,
    updated: Date,
    name: string,
    counts: ImportCounts,
    status: ImportStatus,
}

export interface ImportCounts {
    total: number,
    errored: number,
}

export enum ImportStatus {
    New = 10,
    Processing = 50,
    Complete = 100,
}

export interface ImportList {
    data: Import[],
    continuation?: string,
}

export async function GetImports(continuation?: string) : Promise<ImportList> {
    var url = "imports";
    if (continuation) url += `?continuation=${continuation}`;
    var response = await ApiGet(url);
    if (response.status === 200) return response.data;
    throw response.data;
}

export async function UploadImportFile(file: File) : Promise<Import> {
    const formData = new FormData();
    if (file) formData.append('file', file, file.name);
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };
    var response = await ApiPost("Imports", formData, config);
    return response.status === 200 ? response.data : null;
}

export async function ListImportInvitations(
    importId: string,
    continuation?: string): Promise<InvitationList> {
    var url = `imports/${importId}/invitations`;
    if (continuation) url += `?continuation=${continuation}`;
    var response = await ApiGet(url);
    if (response.status === 200) return response.data;
    throw response.data;
}