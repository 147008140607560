import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { GetInvitation, Invitation, InvitationStatus } from "../../lib";
import { IonPage, IonContent } from "@ionic/react";
import ContentHeader from "../layout/controls/ContentHeader";
import MerchantApiKeysPanel from "./controls/MerchantApiKeys";
import PageSpinner from "../controls/shared/PageSpinner";

interface InvitationViewParams {
    id: string,
};

export default function InvitiationViewPage() {
    const { id } = useParams<InvitationViewParams>();
    const [invitation, setInvitation] = useState<Invitation>();
    const lockApiCalls = useRef(false);

    useEffect(() => {
        if (!id || lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        try {
            GetInvitation(id).then(response => {
                setInvitation(response);
            });
        }
        finally {
            lockApiCalls.current = false;
        }
    }, []);

    if (!invitation) return <PageSpinner />;

    return <>
        <IonPage>
            <IonContent className="ion-padding" fullscreen>
                <ContentHeader />
                <div style={{ margin: "80px", marginTop: "40px" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between", marginBottom: "20px" }}>
                        <h1>Client Invitation View</h1>
                    </div>
                    <label><strong>Client Business Name</strong></label>
                    <p>{invitation.payload.merchantName}</p>

                    <label><strong>Client Contact Email</strong></label>
                    <p>{invitation.payload.merchantEmail}</p>

                    <label><strong>Invitation Status</strong></label>
                    <p>
                        {InvitationStatus[invitation.status]}
                    </p>

                    {invitation?.association?.merchantId && <MerchantApiKeysPanel merchantId={invitation.id} />}
                </div>
            </IonContent>
        </IonPage>
    </>;
}