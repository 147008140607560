import { InteractionRequiredAuthError, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, tokenRequest } from "../../authConfig";
import { GetConfig } from "./Config";

let msalInstance: IPublicClientApplication;
let semaphore = false;

export async function GetMsalInstance() : Promise<IPublicClientApplication> {
    if (!msalInstance && !semaphore) {
        semaphore = true;
        msalInstance = await BuildMsalInstance();
    }

    return msalInstance;
};

export async function BuildMsalInstance(altAuthority?: string) {
    const config = await GetConfig();
    const msal = msalConfig;
    msal.auth.redirectUri = config.auth.redirectUrl;
    if (altAuthority) msal.auth.authority = altAuthority;
    const newInstance = new PublicClientApplication(msal);
    return newInstance;
}

export async function GetAccessToken() {
    var msalInstance = await GetMsalInstance();
    const accounts = msalInstance.getAllAccounts();
    const account = accounts[0];
    const request = { ...tokenRequest, account };

    try {
        var token = await msalInstance.acquireTokenSilent(request);
        return token?.accessToken;
    } 
    catch (error){
        if (error instanceof InteractionRequiredAuthError) {
            msalInstance.acquireTokenRedirect(request).catch(e => {
                console.error(e);
            });
        }
    }
}

export async function Logout() {
    var msalInstance = await GetMsalInstance();
    msalInstance.logoutRedirect();
}