import { RowWithDynamicGutter } from "../controls/shared/Grid";

export default function AwaitingApprovalPage() {
    return <>
        <div style={{ marginTop: "150px" }}>
            <RowWithDynamicGutter>
                Thanks for registering, we'll approve you soon
            </RowWithDynamicGutter>
        </div>
    </>
}