import { IonContent, IonHeader, IonIcon, IonImg, IonItem, IonMenu, IonMenuToggle, IonPage, IonRouterOutlet, IonSplitPane, IonToolbar } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';
import { useHistory } from "react-router";

/* Theme variables */
import { homeOutline, searchOutline, calculatorOutline, exitOutline, cloudOutline } from 'ionicons/icons';
import ImportListPage from '../imports/ImportList';
import { useContext } from 'react';
import AwaitingApprovalPage from '../partners/AwaitingApproval';
import { AccountContext } from '../controls/nav/AccountProvider';
import { Routes } from '../../Routes';
import ImportCreatePage from '../imports/ImportCreate';
import ImportViewPage from '../imports/ImportView';

import './Dashboard.css'; // only for items specific to this page e.g. header image
import { Logout } from '../../lib';
import InvitationListPage from '../invitations/InvitationList';
import InvitationViewPage from '../invitations/InvitationView';
import InvitationCreatePage from '../invitations/InvitationCreate';
import PartnerApiKeysPage from '../partners/PartnerApiKeys';
import PartnerWebhookPage from '../partners/PartnerWebhook';

export default function HomePage() {
    const history = useHistory();
    const accountContext = useContext(AccountContext);

    const paths = [
        { name: 'Home', url: '/', icon: homeOutline },
        { name: 'Client Search', url: Routes.InvitationSearch, icon: searchOutline },
        { name: 'API Keys', url: Routes.ApiKeys, icon: calculatorOutline },
        { name: 'Webhook', url: Routes.Webhook, icon: cloudOutline },
    ];

    if (!accountContext?.partner) return <></>;

    async function doLogout() {
        await Logout();
        accountContext?.refresh();
    }

    return (
        accountContext.partner.status !== "approved"
            ? <AwaitingApprovalPage />
            : <IonReactRouter>
                <div className='backgroundSplit'>
                    <IonSplitPane contentId="main" when="md">
                        <IonMenu contentId="main" >
                            <IonHeader>
                                <IonToolbar color={"#ffffff"}>
                                    <div className='headerImage'>
                                        <IonImg src='/assets/img/logo.svg'></IonImg>
                                    </div>
                                    <h5 className="center">
                                        {accountContext.partner.name}
                                    </h5>
                                </IonToolbar>
                            </IonHeader>
                            <IonContent>
                                {paths.map((item, index) => (
                                    <IonMenuToggle autoHide={false} key={index}>
                                        <IonItem className='splitmenu' routerLink={item.url} routerDirection="forward">
                                            <IonIcon icon={item.icon} slot="start"></IonIcon>
                                            {item.name}
                                        </IonItem>
                                    </IonMenuToggle>
                                ))}
                            </IonContent>
                            <IonMenuToggle autoHide={false} key={99}>
                                <IonItem className='splitmenu' button onClick={() => doLogout()}>
                                    <IonIcon icon={exitOutline} slot="start"></IonIcon>
                                    Logout
                                </IonItem>
                            </IonMenuToggle>
                        </IonMenu>
                        <div id="main">
                            <IonRouterOutlet>
                                <Route path="/" render={() => <InvitationListPage title="Current Clients" showSearchFilters={false} />} exact />
                                <Route path={Routes.Invitation(":id")} component={InvitationViewPage} exact />
                                <Route path={Routes.Imports} component={ImportListPage} exact />
                                <Route path={Routes.Import(":id")} component={ImportViewPage} exact />
                                <Route path={Routes.ImportCreate} component={ImportCreatePage} exact />
                                <Route path={Routes.InvitationSearch} render={() => <InvitationListPage title="Client Search" showSearchFilters={true} />} exact />
                                <Route path={Routes.ApiKeys} component={PartnerApiKeysPage} exact />
                                <Route path={Routes.InvitationCreate} component={InvitationCreatePage} exact />
                                <Route path={Routes.Webhook} component={PartnerWebhookPage} exact />
                            </IonRouterOutlet>
                        </div>
                    </IonSplitPane>
                </div>
            </IonReactRouter>
    );
};