import { useHistory } from "react-router";
import { LinkButton } from "../controls/shared/Buttons";
import { Routes } from "../../Routes";
import { useEffect, useRef, useState } from "react";
import { Invitation, InvitationStatus, ListInvitations } from "../../lib";
import InvitationSearchPanel from "./controls/InvitationSearchPanel";
import { IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonPage } from "@ionic/react";
import ContentHeader from "../layout/controls/ContentHeader";

export interface InvitationListProps {
    showSearchFilters?: boolean;
    title?: string;
};

export default function InvitationListPage({ title, showSearchFilters }: InvitationListProps) {
    const history = useHistory();
    const [continuation, setContinuation] = useState<string | undefined>();
    const [invitations, setInvitations] = useState<Invitation[]>();
    const lockApiCalls = useRef(false);

    useEffect(() => { RetrieveData(); }, []);

    async function RetrieveData(searchText?: string) {
        if (lockApiCalls.current === true) return;
        lockApiCalls.current = true;

        try {
            const result = await ListInvitations(searchText, continuation);

            if (continuation && invitations) setInvitations(prev => [...prev!, ...result.data]);
            else setInvitations(result.data);

            setContinuation(result.continuation);
        }
        finally {
            lockApiCalls.current = false;
        }
    }

    return <>
        <IonPage>
            <IonContent className="ion-padding" fullscreen>
                <ContentHeader />
                <div style={{ margin: "80px", marginTop: "40px" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "end", justifyContent: "space-between", marginBottom: "20px" }}>
                        <h1>{title}</h1>
                    </div>
                    {showSearchFilters && <InvitationSearchPanel onClick={searchText => RetrieveData(searchText)} />}
                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th><strong>Name/Email</strong></th>
                                <th><strong>Invitation Status</strong></th>
                            </tr>
                            <tr style={{ lineHeight: "10px" }}><th>&nbsp;</th></tr>
                        </thead>
                        <tbody>
                            {invitations?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <LinkButton onClick={() => history.push(Routes.Invitation(item.id))} color="secondary" style={{ marginBottom: "-5px" }}>
                                                {item.payload.merchantName}
                                            </LinkButton>
                                            <br />
                                            {item.payload.merchantEmail}
                                        </td>
                                        <td style={{ color: item.status === InvitationStatus.Completed ? "#17DA36" : "#FFA700" }}>
                                            {InvitationStatus[item.status]}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    {continuation && <IonInfiniteScroll threshold="200px" onIonInfinite={() => RetrieveData()}>
                        <IonInfiniteScrollContent loadingSpinner="bubbles" loadingText="Loading...">
                        </IonInfiniteScrollContent>
                    </IonInfiniteScroll>}
                </div>
            </IonContent>
        </IonPage>
    </>;
}