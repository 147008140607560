import { IonApp, setupIonicReact } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';

import '@ionic/react/css/core.css';
import './App.css';
import './theme/variables.css';

import { EventType, IPublicClientApplication } from '@azure/msal-browser';
import { AppConfig, GetConfig, GetMsalInstance } from './lib';
import AccountProvider, { AccountConsumer, AccountContext, AccountContextType } from './pages/controls/nav/AccountProvider';
import GlobalToastProvider from './pages/controls/shared/GlobalToastProvider';
import Loading from './pages/controls/shared/Loading';
import HomePage from './pages/layout/Dashboard';
import RegisterPage from './pages/partners/Register';

setupIonicReact();

function App() {
    const accountContext = useContext(AccountContext);
    const [config, setConfig] = useState<AppConfig>();

    useEffect(() => {
        let callbackId: any;
        let msalInstance: IPublicClientApplication;

        async function addAccountCallback() {
            msalInstance = await GetMsalInstance();

            callbackId = msalInstance.addEventCallback(async (message: any) => {
                if (message.eventType === EventType.HANDLE_REDIRECT_END) {
                    if (accountContext) accountContext.refresh();
                }
            });
        }

        const result = addAccountCallback();

        return function () {
            if (callbackId) {
                msalInstance.removeEventCallback(callbackId);
            }
        }
    }, []);

    useEffect(() => { GetConfig().then(c => setConfig(c)); }, []);

    function renderContent(ctx: AccountContextType) {
        if (!ctx.account) return <></>;
        if (ctx.account.applied) return <HomePage />
        return <RegisterPage />
    }

    function hideStagingBanner() {
        if (config?.isStaging === true || config?.isDevelopment === true || config?.isSandbox === true) {
            return 'stagingBanner'
        }
        else {
            return 'ion-hide'
        }
    }

    function environmentBannerName() {
        if (config?.isSandbox === true) {
            return 'Sandbox'
        }
        else if (config?.isStaging === true) {
            return 'Staging'
        }
        else if (config?.isDevelopment === true) {
            return 'Development'
        }
        else {
            return ''
        }
    }

    function mainNav() {
        return <AccountConsumer>
            {ctx => ctx?.account
                ? <>
                    {renderContent(ctx)}
                </>
                :
                <Loading />
            }
        </AccountConsumer>;
    }

    return (
        <AccountProvider>
            <IonApp>
                <GlobalToastProvider>
                    {mainNav()}
                </GlobalToastProvider>
                <div className={hideStagingBanner()}>
                    {environmentBannerName()}
                </div>
            </IonApp>
        </AccountProvider>
    );
};

export default App;
