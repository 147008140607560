import { useEffect, useRef, useState } from 'react';

import { IPublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";

import App from './App';
import './AppAuth.css';
import { loginRequest } from "./authConfig";
import { BuildMsalInstance, GetMsalInstance } from './lib';
import Loading from './pages/controls/shared/Loading';

const AppAuth = () => {
    const [msalInstance, setMsalInstance] = useState<IPublicClientApplication>();
    const lockApiCalls = useRef(false);

    async function handleLogin() {
        if (!msalInstance) return;
        const path = window.location.pathname;

        if (path.toLowerCase().indexOf('invite') > -1) {
            const inviteMsalInstance = await BuildMsalInstance("https://login.trustist.com/trustistclient.onmicrosoft.com/B2C_1_CustomHTMLSignUp");

             inviteMsalInstance.loginRedirect(loginRequest).catch(e => {
                 console.error(e);
             });

             return;
         }
        
        msalInstance.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    }

    async function getMsalInstance() {
        try {
            var instance = await GetMsalInstance();
            if (instance) setMsalInstance(instance);
        }
        finally {
            lockApiCalls.current = false
        };
    }

    useEffect(() => {
        if (lockApiCalls.current === false) {
            lockApiCalls.current = true;
            getMsalInstance();
        }
    }, []);

    return <>{msalInstance ?
        <MsalProvider instance={msalInstance!}>
            <AuthenticatedTemplate>
                <App />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {handleLogin}
            </UnauthenticatedTemplate>
        </MsalProvider>
        :
        <Loading />

    }</>;
}

export default AppAuth;